import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useMemo, useState } from 'react';
import Text from '@jetbrains/ring-ui/components/text/text';
import Select from '@jetbrains/ring-ui/components/select/select';
import Panel from '@jetbrains/ring-ui/components/panel/panel';
import Button from '@jetbrains/ring-ui/components/button/button';
import { noop } from 'lodash';
import { Content } from '@jetbrains/ring-ui/components/island/island';
import Loader from '@jetbrains/ring-ui/components/loader/loader';
import { ConstituentDialog } from '@modules/constituents/constituent-dialog';
import { useDeleteProviderMutation, useProviderInfo } from '@common/queries/ai-enterprise';
import { assignTestId } from '@common/infra/tests/assign-test-id';
import { AI_PROVIDERS } from '../../constants';
import styles from './delete-provider-dialog.css';
const MIGRATION_OPTIONS = [
    {
        key: 'disable',
        label: 'Disable AI in profiles'
    },
    {
        key: 'migrate',
        label: 'Switch to another provider'
    }
];
export function DeleteProviderDialog({ provider, availableProviders, onCloseAttempt }) {
    const [migrationStrategy, setMigrationStrategy] = useState(undefined);
    const [newLLMProvider, setNewLLMProvider] = useState(undefined);
    const providerLabel = useMemo(() => { var _a; return (_a = AI_PROVIDERS.find(({ key }) => key === provider)) === null || _a === void 0 ? void 0 : _a.label; }, [provider]);
    const { data, isLoading } = useProviderInfo(provider);
    const { mutateAsync } = useDeleteProviderMutation(providerLabel !== null && providerLabel !== void 0 ? providerLabel : provider, provider);
    const activeIdeProfilesCount = useMemo(() => data === null || data === void 0 ? void 0 : data.activeIdeProfilesCount, [data]);
    const handleStrategySelection = (strategy) => {
        setMigrationStrategy(strategy === null || strategy === void 0 ? void 0 : strategy.key);
    };
    const handleNewProviderSelection = (strategy) => {
        setNewLLMProvider(strategy === null || strategy === void 0 ? void 0 : strategy.key);
    };
    const handleApplyNewSettings = () => {
        mutateAsync(newLLMProvider)
            .then(() => onCloseAttempt(true))
            .catch(noop);
    };
    if (isLoading) {
        return _jsx(Loader, {});
    }
    return (_jsxs(ConstituentDialog, { title: `Delete ${providerLabel} provider?`, children: [_jsx(Content, { className: styles.container, children: activeIdeProfilesCount !== 0 ? (_jsxs(_Fragment, { children: [_jsxs(Text, { children: [providerLabel, " is currently enabled in ", activeIdeProfilesCount, " profiles.", _jsx("br", {}), "Choose a migration strategy to continue."] }), _jsx(Select, { selectedLabel: "Choose a migration strategy", data: MIGRATION_OPTIONS, selected: MIGRATION_OPTIONS.find(({ key }) => key === migrationStrategy), onSelect: handleStrategySelection }), migrationStrategy === 'migrate' && (_jsx(Select, { selectedLabel: "Choose provider", data: AI_PROVIDERS.filter(({ key }) => availableProviders.includes(key) && key !== provider), selected: AI_PROVIDERS.find(({ key }) => key === newLLMProvider), onSelect: handleNewProviderSelection }))] })) : (_jsxs(Text, { children: ["Are you sure you would like to delete ", providerLabel, " AI Provider?"] })) }), _jsxs(Panel, { children: [_jsx(Button, { onClick: handleApplyNewSettings, loader: isLoading, primary: true, disabled: activeIdeProfilesCount !== 0
                            ? migrationStrategy === undefined ||
                                (migrationStrategy === 'migrate' && newLLMProvider === undefined)
                            : false, ...assignTestId('confirm-button'), children: "Apply" }), _jsx(Button, { ...assignTestId('cancel-button'), onClick: () => onCloseAttempt(false), children: "Cancel" })] })] }));
}
