// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.wrapper__d7e {
  padding: calc(var(--ring-unit) * 2) 0 calc(var(--ring-unit) * 3) 0;
}

.title__cb8.title__cb8 {
  margin-bottom: 0;
}

.title__cb8 + .section__c79 {
  margin-top: calc(var(--ring-unit) * 2);
}

.section__c79 + .section__c79 {
  margin-top: calc(var(--ring-unit) * 1.5);
}

.section__c79 + .addProviderBtn__a66 {
  margin-top: calc(var(--ring-unit) * 2);
}
`, "",{"version":3,"sources":["webpack://./src/modules/ai/ai-settings-form/sections/ai-configuration/ai-configuration-section.css"],"names":[],"mappings":"AAAA;EACE,kEAAkE;AACpE;;AAEA;EACE,gBAAgB;AAClB;;AAEA;EACE,sCAAsC;AACxC;;AAEA;EACE,wCAAwC;AAC1C;;AAEA;EACE,sCAAsC;AACxC","sourcesContent":[".wrapper {\n  padding: calc(var(--ring-unit) * 2) 0 calc(var(--ring-unit) * 3) 0;\n}\n\n.title.title {\n  margin-bottom: 0;\n}\n\n.title + .section {\n  margin-top: calc(var(--ring-unit) * 2);\n}\n\n.section + .section {\n  margin-top: calc(var(--ring-unit) * 1.5);\n}\n\n.section + .addProviderBtn {\n  margin-top: calc(var(--ring-unit) * 2);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": `wrapper__d7e`,
	"title": `title__cb8`,
	"section": `section__c79`,
	"addProviderBtn": `addProviderBtn__a66`
};
export default ___CSS_LOADER_EXPORT___;
