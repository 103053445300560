import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useRef } from 'react';
import { H3 } from '@jetbrains/ring-ui/components/heading/heading';
import Radio from '@jetbrains/ring-ui/components/radio/radio';
import Icon from '@jetbrains/ring-ui/components/icon';
import trashIcon from '@jetbrains/icons/trash';
import classNames from 'classnames';
import Tooltip from '@jetbrains/ring-ui/components/tooltip/tooltip';
import { assignTestId } from '@common/infra/tests/assign-test-id';
import { useIsFocused } from '@common/hooks/use-is-focused';
import styles from './provider-form-base.css';
export function ProviderFormBase({ label, children, isDefaultProvider, onDefaultProviderChange, onDelete, isDirty, className, ...rest }) {
    const wrapperRef = useRef(null);
    const [focused] = useIsFocused(wrapperRef, !!isDirty);
    return (_jsxs("section", { className: classNames(styles.container, className, {
            [styles.isDefaultProvider]: isDefaultProvider,
            [styles.focused]: focused
        }), ...assignTestId('provider-form', rest), ref: wrapperRef, children: [_jsxs("div", { className: styles.headerContainer, children: [_jsx("div", { className: styles.titleContainer, children: _jsx(H3, { children: label }) }), _jsxs("div", { className: styles.actionsContainer, children: [_jsx(Tooltip, { title: "Only one default provider can be selected", children: _jsx(Radio.Item, { checked: isDefaultProvider, value: "", onChange: onDefaultProviderChange, children: "Default" }) }), _jsx(Icon, { ...assignTestId('remove'), glyph: trashIcon, onClick: onDelete, className: styles.deleteIcon })] })] }), children] }));
}
