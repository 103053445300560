import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import classNames from 'classnames';
import { AiAvailableProviders, AiConfigurationSection, AiFormActions, AiGeneralSection } from './sections';
import { tAiSettingsFormSchema } from './ai-settings-form-schema';
import styles from './ai-settings-form.css';
export function AiSettingsForm({ data, className }) {
    const form = useForm({
        defaultValues: data.data,
        resolver: zodResolver(tAiSettingsFormSchema)
    });
    return (_jsxs("div", { className: classNames(styles.formContainer, className), children: [_jsxs("div", { className: styles.mainContent, children: [_jsx(AiGeneralSection, { className: styles.section, form: form }), _jsx(AiConfigurationSection, { className: styles.section, form: form, defaultValues: data.data }), _jsx(AiFormActions, { className: styles.section, form: form })] }), _jsx("div", { children: _jsx(AiAvailableProviders, { form: form }) })] }));
}
