// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.title__af6.title__af6 {
  margin-bottom: 0;
}

.form__e5f {
  margin-top: calc(var(--ring-unit) * 3);
}
`, "",{"version":3,"sources":["webpack://./src/pages/configuration-page/license/ai-settings/ai-settings-page.css"],"names":[],"mappings":"AAAA;EACE,gBAAgB;AAClB;;AAEA;EACE,sCAAsC;AACxC","sourcesContent":[".title.title {\n  margin-bottom: 0;\n}\n\n.form {\n  margin-top: calc(var(--ring-unit) * 3);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"title": `title__af6`,
	"form": `form__e5f`
};
export default ___CSS_LOADER_EXPORT___;
