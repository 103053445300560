import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useMemo } from 'react';
import { H2 } from '@jetbrains/ring-ui/components/heading/heading';
import cn from 'classnames';
import Select from '@jetbrains/ring-ui/components/select/select';
import { isNil } from 'lodash';
import { useFormState } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { hideConfirm } from '@jetbrains/ring-ui/components/confirm-service/confirm-service';
import { JetbrainsAiProviderForm, OpenAiProviderForm } from '@modules/ai/common/forms';
import { AI_PROVIDERS, DEFAULT_PROVIDER_VALUES } from '@modules/ai/common/constants';
import { DeleteProviderDialog } from '@modules/ai/common/dialogs/delete-provider-dialog/delete-provider-dialog';
import { AiDisableWithoutProvidersDialog } from '@modules/ai/common/dialogs/ai-disable-dialog/ai-disable-without-providers-dialog';
import confirmDefaultProviderChange from '@modules/ai/common/dialogs/confirm-default-provider-change/confirm-default-provider-change';
import { GoogleAiProviderForm } from '@modules/ai/common/forms/google-ai-provider-form/google-ai-provider-form';
import { useOpenModal } from '@common/modals/hooks';
import { assignTestId } from '@common/infra/tests/assign-test-id';
import { ProviderFormBase } from '../../common';
import styles from './ai-configuration-section.css';
export function AiConfigurationSection(props) {
    const { form, defaultValues, className } = props;
    const { setValue, getValues, watch, control } = form;
    const navigate = useNavigate();
    const providers = getValues('availableProviders');
    const defaultprovider = watch('defaultProvider');
    const deleteProviderDialog = useOpenModal(DeleteProviderDialog, {
        availableProviders: providers,
        provider: ''
    });
    const deleteLastProviderDialog = useOpenModal(AiDisableWithoutProvidersDialog, {
        provider: ''
    });
    const providerFormValues = watch('provider');
    const providerOptions = useMemo(() => AI_PROVIDERS.filter(option => isNil(providerFormValues[option.key]) && (providers === null || providers === void 0 ? void 0 : providers.includes(option.key))), [providerFormValues, providers]);
    const handleSelection = (selectedProvider) => {
        if (selectedProvider) {
            setValue('provider', {
                ...providerFormValues,
                [selectedProvider]: DEFAULT_PROVIDER_VALUES[selectedProvider]
            }, { shouldDirty: true });
        }
    };
    const handleDeleteProvider = (provider) => {
        var _a, _b;
        if (isNil((_a = defaultValues === null || defaultValues === void 0 ? void 0 : defaultValues.provider) === null || _a === void 0 ? void 0 : _a[provider])) {
            if (defaultprovider === provider) {
                setValue('defaultProvider', (_b = defaultValues === null || defaultValues === void 0 ? void 0 : defaultValues.defaultProvider) !== null && _b !== void 0 ? _b : 'jetbrains', {
                    shouldDirty: true
                });
            }
            setValue('provider', { ...providerFormValues, [provider]: null }, { shouldDirty: true });
        }
        else {
            const isLastProvider = Object.values(providerFormValues).filter(item => item !== null).length === 1;
            if (isLastProvider) {
                deleteLastProviderDialog({ provider }).then(success => {
                    if (success) {
                        navigate('/configuration/license-activation');
                    }
                });
            }
            else {
                const otherProviders = Object.values(providerFormValues)
                    .filter(Boolean)
                    .filter(other => other.id !== provider);
                deleteProviderDialog({ provider }).then(confirm => {
                    if (confirm && provider) {
                        setValue('provider', {
                            ...providerFormValues,
                            [provider]: null
                        });
                        if (defaultprovider === provider) {
                            setValue('defaultProvider', otherProviders[0].id);
                        }
                    }
                });
            }
        }
    };
    const handleDefaultProviderChange = (provider) => {
        confirmDefaultProviderChange(defaultprovider)
            .then(() => {
            setValue('defaultProvider', provider, { shouldDirty: true });
        })
            .catch(hideConfirm);
    };
    const isJetbrainsFormDirty = useFormState({ control, name: 'provider.jetbrains' }).isDirty;
    const isGoogleFormDirty = useFormState({ control, name: 'provider.google' }).isDirty;
    const isOpenAiFormDirty = useFormState({ control, name: 'provider.openai' }).isDirty;
    return (_jsxs("section", { className: cn(styles.wrapper, className), ...assignTestId('ai-configuration-section'), children: [_jsx(H2, { className: styles.title, children: "AI Providers" }), !isNil(providerFormValues.jetbrains) && (_jsx(ProviderFormBase, { isDefaultProvider: defaultprovider === 'jetbrains', label: "JetBrains AI", onDefaultProviderChange: () => handleDefaultProviderChange('jetbrains'), onDelete: () => handleDeleteProvider('jetbrains'), isDirty: isJetbrainsFormDirty, className: styles.section, ...assignTestId('jetbrains'), children: _jsx(JetbrainsAiProviderForm, { form: form }) })), !isNil(providerFormValues.openai) && (_jsx(ProviderFormBase, { isDefaultProvider: defaultprovider === 'openai', label: "Open AI", onDefaultProviderChange: () => handleDefaultProviderChange('openai'), onDelete: () => handleDeleteProvider('openai'), isDirty: isOpenAiFormDirty, className: styles.section, ...assignTestId('openai'), children: _jsx(OpenAiProviderForm, { form: form }) })), !isNil(providerFormValues.google) && (_jsx(ProviderFormBase, { isDefaultProvider: defaultprovider === 'google', label: "Google Vertex AI", onDefaultProviderChange: () => handleDefaultProviderChange('google'), onDelete: () => handleDeleteProvider('google'), isDirty: isGoogleFormDirty, className: styles.section, ...assignTestId('google'), children: _jsx(GoogleAiProviderForm, { form: form }) })), providerOptions.length !== 0 && (_jsx(Select, { label: "Add provider", selected: { key: 'none', label: 'Add provider' }, data: providerOptions, onSelect: val => handleSelection(val === null || val === void 0 ? void 0 : val.key), className: styles.addProviderBtn, ...assignTestId('provider-selector') }))] }));
}
